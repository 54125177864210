import { isLemonSubscribeStatusValid } from '@/lib/lemonsqueezy/lemon'
import { SubscriptionResponse } from '../types/site'

export const isUserSubscriptionActived = (
  subscription: SubscriptionResponse | undefined | null
) => {
  if (!subscription || !subscription.id) {
    return false
  }
  return isLemonSubscribeStatusValid(subscription.status)
}
