import { SubscriptionResponse } from '../types/site'
import { isPaymentEnabled } from './isPaymentEnabled'
import { isUserSubscriptionActived } from './isUserSubscriptionActived'

export enum ProPlanStatus {
  NeedLogin = 'need-login',
  Coming = 'coming',
  Loading = 'loading',
  NotSubscribed = 'not-subscribed',
  Subscribed = 'subscribed',
}

export function getProPlanStatus(
  subscription: SubscriptionResponse | undefined,
  loggedIn: boolean
): ProPlanStatus {
  if (!loggedIn) {
    return ProPlanStatus.NeedLogin
  }
  if (!isPaymentEnabled()) {
    return ProPlanStatus.Coming
  }
  if (subscription === undefined) {
    return ProPlanStatus.NotSubscribed
  }
  if (isUserSubscriptionActived(subscription)) {
    return ProPlanStatus.Subscribed
  }

  return ProPlanStatus.NotSubscribed
}
