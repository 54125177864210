export const isPaymentEnabled = () => {
  return true
  // if (typeof window !== 'undefined') {
  //   if (window?.localStorage?.getItem('enablePaddle') === '1') {
  //     return true
  //   }
  // }

  // if (process.env.NEXT_PUBLIC_DOMAIN_NAME === 'testnoto.site') {
  //   return true
  // }
  // return false
}
