import { cn } from '@/lib/utils'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'

interface PricingCardProps {
  name: string
  priceMonthly: string
  description: string
  features: string[]
  actionText: string
  highlight?: boolean
  children?: ReactNode
}
const PricingCard: React.FC<PricingCardProps> = (props) => {
  return (
    <div
      className={cn(
        'flex h-max flex-col overflow-hidden rounded-lg shadow-lg',
        props.highlight ? 'bg-[#0099ff]' : 'bg-white'
      )}
    >
      <div className="px-6 py-8 sm:p-10 sm:pb-6">
        <div>
          <h3
            className={cn(
              'inline-flex py-1 text-sm font-semibold tracking-wide',
              props.highlight ? 'text-white' : 'text-black'
            )}
            id="tier-standard"
          >
            {props.name}
          </h3>
        </div>
        <div
          className={cn(
            'mt-4 flex items-baseline text-6xl font-extrabold',
            props.highlight ? 'text-white' : 'text-black'
          )}
        >
          {props.priceMonthly}
          <span
            className={cn(
              'ml-1 text-2xl font-medium',
              props.highlight ? 'text-white opacity-50' : 'text-black'
            )}
          >
            /month
          </span>
        </div>
        <p
          className={cn(
            'mt-5 text-lg',
            props.highlight ? 'text-white' : 'text-gray-500'
          )}
        >
          {props.description}
        </p>
      </div>
      <div className="flex flex-1 flex-col justify-between space-y-6 px-6 pt-6 pb-8 sm:p-10 sm:pt-6">
        <ul role="list" className="space-y-4">
          {props.features.map((feature, idx) => (
            <li key={idx} className="flex items-start">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className={cn(
                    'h-6 w-6',
                    props.highlight ? 'text-white' : 'text-[#0099ff]'
                  )}
                  aria-hidden="true"
                />
              </div>
              <p
                className={cn(
                  'ml-3 text-base ',
                  props.highlight ? 'text-white' : 'text-gray-700'
                )}
              >
                {feature}
              </p>
            </li>
          ))}
        </ul>
        {props.children}
      </div>
    </div>
  )
}
export default PricingCard
