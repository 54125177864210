import type { Subscription } from '@prisma/client'
import { LemonApiKey, LemonInstanceName, LemonStoreId } from './lemonEnv'

export async function activateLicenseKey(licenseKey: string) {
  // https://docs.lemonsqueezy.com/help/licensing/license-api
  console.log('========= is going to activate license key')
  const response = await fetch(
    `https://api.lemonsqueezy.com/v1/licenses/activate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${LemonApiKey ?? ''}`,
      },
      body: JSON.stringify({
        license_key: licenseKey,
        instance_name: LemonInstanceName,
      }),
    }
  )
  const result = await response.json()
  return result.activated
}

export async function validateLicenseKey(licenseKey: string) {
  // https://docs.lemonsqueezy.com/help/licensing/license-api
  const response = await fetch(
    `https://api.lemonsqueezy.com/v1/licenses/validate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${LemonApiKey ?? ''}`,
      },
      body: JSON.stringify({
        license_key: licenseKey,
      }),
    }
  )
  const result = await response.json()

  if (!result.valid) {
    return {
      isValid: false,
    }
  }
  // both test mode and prod mode has the same store_id
  if (String(result.meta.store_id) !== LemonStoreId) {
    return { isValid: false }
  }

  const total = result.license_key.activation_limit
  const used = result.license_key.activation_usage
  return {
    isValid: result.valid && used < total,
    total,
    used,
  }
}

export async function clientValidateLicenseKey(licenseKey: string) {
  return fetch(`/api/lemonsqueezy/check-license-key?licenseKey=${licenseKey}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then((v) => v.json())
}

export const isLemonSubscribeStatusValid = (status: Subscription['status']) => {
  const validSubscribeStatus = [
    'active',
    'on_trial',
    'paused',
    // 'past_due',
    'cancelled',
  ]
  return validSubscribeStatus.includes(status)
}
