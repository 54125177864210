import { cn } from '@/lib/utils'
import { motion } from 'framer-motion'
import { FC } from 'react'

export type ToggleState = 'month' | 'year'

interface PricingToggleProps {
  state: ToggleState
  onToggle: (s: ToggleState) => void
}
const PricingToggle = (props: PricingToggleProps) => {
  return (
    <div className="relative mt-6 flex self-center rounded-lg bg-gray-200 p-0.5 sm:mt-8">
      <Pricing
        selfPlan="year"
        onClick={() => props.onToggle('year')}
        active={props.state === 'year'}
      />

      <Pricing
        selfPlan="month"
        onClick={() => props.onToggle('month')}
        active={props.state === 'month'}
      />
    </div>
  )
}

export default PricingToggle

const Pricing: FC<{
  selfPlan: ToggleState
  onClick: () => void
  active: boolean
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      className={cn(
        'relative w-1/2 whitespace-nowrap rounded-md border border-transparent py-2 text-sm font-medium sm:w-auto sm:px-8 text-center',
        props.active ? 'text-[#0099ff]' : 'text-gray-600 hover:text-gray-700'
      )}
    >
      {props.active && <ActiveIndicator />}
      <span className="relative z-20 w-full text-center">
        {props.selfPlan === 'month' ? 'Bill monthly' : 'Bill yearly'}
        {props.selfPlan === 'year' && (
          <span
            className={cn(
              'ml-1 text-[10px]',
              props.active ? 'text-gray-600' : 'text-[#0099ff]'
            )}
          >
            {/* Save 4 months */}
          </span>
        )}
      </span>
    </button>
  )
}

function ActiveIndicator() {
  return (
    <motion.div
      className={cn(
        'absolute inset-0 z-10 whitespace-nowrap rounded-md border py-2 text-sm font-medium sm:w-auto sm:px-8',
        'border-gray-200 bg-white text-gray-900 shadow-sm'
      )}
      layoutId="underline"
    />
  )
}
