'use client'
import PricingCard from '@/components/home/pricing/pricingCard'
import PricingToggle, {
  ToggleState,
} from '@/components/home/pricing/pricingToggle'
import { fetchSubscribeUrl } from '@/lib/client/api/fetchSubscribeUrl'
import ROUTES from '@/lib/constants/ROUTES'
import { BILLING } from '@/lib/constants/section'
import { cn } from '@/lib/utils'
import { getHrefOfSection } from '@/lib/utils/getHrefOfSection'
import { getProPlanStatus, ProPlanStatus } from '@/lib/utils/getProPlanStatus'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

export default function PricingPage() {
  const router = useRouter()
  const { status, data } = useSession()
  const [toggleState, setToggleState] = useState<ToggleState>('year')

  const loggedIn = status === 'authenticated'

  const proPlanStatus = getProPlanStatus(data?.user?.subscription, loggedIn)

  function handleProPlanClick() {
    switch (proPlanStatus) {
      case ProPlanStatus.NeedLogin: {
        signIn(
          'google',
          {
            callbackUrl: `${window.location.origin}${ROUTES.Pricing}?subscribe=${toggleState}`,
          },
          { prompt: 'login' }
        )
        break
      }
      case ProPlanStatus.Coming:
        break
      case ProPlanStatus.Loading:
        break
      case ProPlanStatus.NotSubscribed: {
        const user = data?.user
        if (!user) {
          return
        }
        fetchSubscribeUrl(toggleState === 'year' ? 'yearly' : 'monthly').then(
          (url) => {
            router.push(url)
          }
        )
        break
      }
      case ProPlanStatus.Subscribed:
        router.push(getHrefOfSection(BILLING))
        break
    }
  }

  return (
    <section id="pricing" className="py-24 container">
      <div className="sm:align-center sm:flex sm:flex-col">
        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
          Pricing Plans
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          You focus on creating, we spread it to the world!
        </p>
        <PricingToggle state={toggleState} onToggle={setToggleState} />
      </div>
      <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="relative z-10 mx-auto max-w-4xl">
            <div className="mx-auto max-w-md space-y-4 lg:grid  lg:gap-5 lg:space-y-0">
              <PricingCard
                name="Pro"
                priceMonthly={toggleState === 'year' ? '$4' : '$6'}
                description="For creators."
                features={[
                  '1 site',
                  'Free noto.so subdomain',
                  'Custom Domain Name',
                  'Unlimited blog pages',
                  'All themes',
                  'Customized site owner name',
                  'Without noto.so banner',
                  'Navigation links',
                  'RSS feed',
                ]}
                actionText="Try for free"
                highlight
              >
                <div className={cn('rounded-md shadow-lg')}>
                  <div
                    onClick={handleProPlanClick}
                    className={cn(
                      'flex cursor-pointer items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium',
                      'bg-white text-[#0099ff] hover:opacity-60'
                    )}
                  >
                    {getProPlanActionText(proPlanStatus)}
                  </div>
                </div>
              </PricingCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function getProPlanActionText(status: ProPlanStatus) {
  switch (status) {
    case ProPlanStatus.NeedLogin:
      return 'Subscribe'
    case ProPlanStatus.Coming:
      return 'Coming soon!'
    case ProPlanStatus.Loading:
      return '...'
    case ProPlanStatus.NotSubscribed:
      return 'Subscribe'
    case ProPlanStatus.Subscribed:
      return 'Manage subscription'
  }
}
